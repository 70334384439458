body {
    margin: 0;
    font-family: "Graphik LCG", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --accent-color: rgb(228, 70, 33);
}

#root {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    flex: 1 1 auto;
}

@font-face {
    font-family: 'Graphik LCG';
    src: local('Graphik LCG'), url(../fonts/GraphikLCG-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Graphik LCG';
    src: local('Graphik LCG'), url(../fonts/GraphikLCG-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Graphik LCG'), url(../fonts/Montserrat-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Graphik LCG'), url(../fonts/Montserrat-Medium.ttf) format('truetype');
    font-weight: 600;
}

